import React, { useState } from 'react';
import { Button } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { FaPen } from "react-icons/fa";
import { Slide } from '@mui/material';
import { FaPenFancy } from "react-icons/fa";
import { FaPencil } from 'react-icons/fa6';
import { RiBallPenFill } from 'react-icons/ri';


const useStyles = makeStyles((theme) => ({
  icon: {
    margin: '0px',
    fontSize: '24px',
    borderRadius: '50%',
  },
  iconButton: {
    borderRadius: '60%',
    margin: '5px',
    padding: '15px',
    position: 'relative',
  },
  dropdown: {
    position: 'absolute',
    top: '50px',
    left: 0,
    backgroundColor: theme.palette.background.paper,
    border: '1px solid #ccc',
    boxShadow: theme.shadows[5],
    borderRadius: '8px',
    padding: theme.spacing(2),
    zIndex: 9999, // Extremely high z-index to ensure visibility
  },
  penPreview: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    gap: theme.spacing(1),
  },
  penIcon: {
    backgroundColor: '#9a9393',
    cursor: 'pointer',
    width: '30px', // Width of the line
  },
  selectedPen: {
    border: '2px solid white',
    backgroundColor : '#282525'
  },
}));

function PenWidthSelector({ onWidthChange }) {
  const [displayDropdown, setDisplayDropdown] = useState(false);
  const [penWidth, setPenWidth] = useState(5); // Default pen width
  const classes = useStyles();

  const penSizes = [2, 5, 7, 10, 14]; // Define available pen sizes (line thicknesses)

  const handlePenSizeClick = (size) => {
    setPenWidth(size);
    onWidthChange(size);
    setDisplayDropdown(false); // Close the dropdown after selection
  };

  return (
    <div style={{ position: 'relative', display: 'inline-block' }}>
      <Button
        onClick={() => setDisplayDropdown(!displayDropdown)}
        className={classes.iconButton}
      >
        <RiBallPenFill className={classes.icon} />
      </Button>

      <Slide appear in={displayDropdown} mountOnEnter unmountOnExit>
        <div className={classes.dropdown}>
          <div className={classes.penPreview}>
            {penSizes.map((size) => (
              <div
                key={size}
                onClick={() => handlePenSizeClick(size)}
                className={`${classes.penIcon} ${penWidth === size ? classes.selectedPen : ''}`}
                style={{
                  height: `${size}px`, // Adjust height based on pen width
                  borderRadius: `${size / 2}px`, // Make the line edges smooth
                }}
              />
            ))}
          </div>
        </div>
      </Slide>
    </div>
  );
}

export default PenWidthSelector;
