import React, { useState, useEffect } from 'react';

import axios from 'axios';
import Api from '../../Api';
import { Box, Button, makeStyles, Modal, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@material-ui/core';
import { MdViewModule } from 'react-icons/md';
import Paper from '@mui/material/Paper';

// Define the type for each prescription item
interface Prescription {
  id: number;
  prescription_url: string;
  patient_id: number;
}

const useStyle = makeStyles((theme) => ({
  icon: {
    color: 'white',
    margin: '5px',
  },
  table: {
    margin : '60px',
    width : '80%',
    minWidth: 650,
    '& th': {
      backgroundColor: '#f4f4f4',
      fontWeight: 'bold',
    },
    '& td': {
      padding: '10px',
    },
  },
  modalImage: {
    width: '80%',
    height: 'auto',
  },
}));

const style = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '50%',
//   bgcolor: 'white',
//   border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};
const ViewPrescriptionList = ({ usermapid }: { usermapid: string | undefined }) => {
  const classes = useStyle();
  const [prescriptionList, setPrescriptionList] = useState<Prescription[]>([]);
  const [selectedPrescription, setSelectedPrescription] = useState<string | null>(null);
  const [isSetPrescription, setPrescriptionFlag] = useState<boolean>(false);

  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const fetchPrescriptions = () => {
    axios.get(`${Api.$getTotalPrescription}/${usermapid}`)
      .then(response => {
        const data = response.data.data as Prescription[];
        setPrescriptionList(data);
        setPrescriptionFlag(true);
        console.log('Total prescriptions:', data);
      })
      .catch(error => {
        console.log(error);
      });
  };

  const handleClick = (url: string) => {
    setSelectedPrescription(url);
    handleOpen(); // Open the modal when a prescription is selected
  };

  const onView = () => {
    fetchPrescriptions();
  };

    useEffect(() => {
        fetchPrescriptions();
      }, [usermapid]);


  return (
    <div>
      {isSetPrescription && (
        <TableContainer component={Paper}>
          <Table className={classes.table} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell>Id</TableCell>
                <TableCell>Patient Id</TableCell>
                <TableCell align="right">Action</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {prescriptionList.map((prescription) => (
                <TableRow key={prescription.id}>
                  <TableCell>{prescription.id}</TableCell>
                  <TableCell>{prescription.patient_id}</TableCell>
                  <TableCell align="right">
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={() => handleClick(prescription.prescription_url)}
                    >
                      View Prescription
                    </Button>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      )}

      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          {selectedPrescription && (
            <img
              src={selectedPrescription}
              alt="Prescription"
              className={classes.modalImage}
            />
          )}
        </Box>
      </Modal>
    </div>
  );
};

export default ViewPrescriptionList;
