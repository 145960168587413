/* eslint-disable no-restricted-globals */
import React, { useEffect } from "react";
import { Button } from "@material-ui/core";
import axios from "axios";
import Api from "../../Api";

const OfflineButton = ({
    controller,
    offlineData,
    drawingOffline,
    usermapid,
    patientid,
    dotsOfEachPage

}) => {
    const RequestOfflineNoteList = () => {
        controller.RequestOfflineNoteList(0, 0);
    };

    const startDrawingOffline = async () => {
        console.log('offtotheMoon', offlineData);
        drawingOffline();
    };

    return (
        <>
            {offlineData?.length ? (
                <Button onClick={startDrawingOffline} style={{ backgroundColor: '#1679AB', color: 'white', borderRadius: '4px', marginTop: '5px', marginBottom: '5px' }} variant="contained">Drawing Offline</Button>
            ) : (
                <Button onClick={RequestOfflineNoteList} style={{ backgroundColor: '#1679AB', color: 'white', borderRadius: '4px', marginTop: '5px', marginBottom: '5px' }} variant="contained">Request Offline</Button>
            )}
        </>
    );
};

export default OfflineButton;



// /* eslint-disable no-restricted-globals */
// import React, { useEffect, useState } from "react";
// import { Button, makeStyles } from "@material-ui/core";
// import { sleep } from "web_view_sdk_test/dist/common";
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import { faSync } from "@fortawesome/free-solid-svg-icons";
// import { MdDraw } from "react-icons/md";

// const useStyle = makeStyles((theme) => ({
//     icon: {
//         color: 'white',
//         margin: '5px',
//     },
// }));


// const OfflineButton = ({
//   controller,
//   offlineData,
//   setOfflineData,
//   strokeProcess,
//   setPageInfo,
//   pageInfo,
//   paperSize,
//   drawingOffline,

// }) => {
//   const [canDraw, setCanDraw] = useState(false);


//   const classes = useStyle();

//    useEffect(() => {
//     console.log('innnnnnnnnnnnnnnnnnnnnnnn offfflineeeeeeeeeee  button', pageInfo , offlineData , canDraw );
//     console.log('cheacking condittion',canDraw && pageInfo && offlineData );
//     //  && imageBlobUrl && paperSize
//     if (canDraw && pageInfo && offlineData) {
//         console.log('start drawing');
//       startDrawingOffline();
//     }
//   }, [canDraw, pageInfo, offlineData]);

//   const RequestOfflineNoteList = () => {
//     controller.RequestOfflineNoteList(0, 0);
//   };

//   const DrawingOffline = async () => {
//     console.log(['drawingggggggofflineeeeeee' , offlineData]);
//         const sobp = offlineData[0].Dots[0].pageInfo;
//         console.log('drawingOffline', sobp );
//         setPageInfo(sobp);
//         setCanDraw(true);
//   };

//   const startDrawingOffline = async () => {
//     console.log('start drawing offline');
//     for (let i = 0; i < offlineData.length; i++) {
//       const dots = offlineData[i].Dots;
//       for (let j = 0; j < dots.length; j++) {
//         const dot = dots[j];
//         console.log('here in thestarttt' , dot);
//         strokeProcess(dot);
//         await sleep(10);
//       }
//     }
//     // setOfflineData(null);
//     setCanDraw(false);
//   };

//   return (
//     <>
//         {offlineData?.length ? (
//         <Button style={{ backgroundColor: '#1679AB', color: 'white', borderRadius: '4px' ,marginTop:'5px' ,marginBottom:'5px'}} onClick={DrawingOffline}><MdDraw className={classes.icon} />
//  Drawing Offline</Button>
//       ) : (
//         <Button style={{ backgroundColor: '#1679AB', color: 'white', borderRadius: '4px' ,marginTop:'5px' ,marginBottom:'5px'}} onClick={RequestOfflineNoteList}> <FontAwesomeIcon icon={faSync} className={classes.icon} /> Sync Offline data</Button>


//       )}
//     </>
//   );
// };

// export default OfflineButton;
