import React, { useEffect, useState } from 'react';
import { Button, makeStyles } from '@material-ui/core';
import { PenController, PenHelper } from 'web_pen_sdk';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAdd, faAnchorLock, faCircleMinus, faPlusCircle, faSubtract, faSync } from '@fortawesome/free-solid-svg-icons';
import { Color } from 'fabric/fabric-impl';
import PenRequestV2 from 'web_pen_sdk/dist/PenCotroller/PenRequestV2';
import axios from 'axios';
import Api from '../../Api';
import { toast } from 'react-toastify';
import SaveButton from './SaveButton';



const useStyle = makeStyles((theme) => ({
    icon: {
        color: 'white',
        margin: '5px',
    },
}));

const DownloadCanvas = ({
    usermapid,
    patientid,
    dotsOfEachPage,
    controller,
    offlineData,
    drawingOffline,
    canvas,
    pageId

}) => {
    const [canvasUrl, setCanvasUrl] = useState<string | null>(null);
    const classes = useStyle();
    function dataURLToBlob(dataURL: string): Blob {
        const arr = dataURL.split(',');
        const mime = arr[0].match(/:(.*?);/)?.[1] || '';
        const bstr = atob(arr[1]);
        let n = bstr.length;
        const u8arr = new Uint8Array(n);
        while (n--) {
          u8arr[n] = bstr.charCodeAt(n);
        }
        return new Blob([u8arr], { type: mime });
      }


    const save = () => {
        if(canvasUrl){

        console.log('saved', usermapid, patientid, dotsOfEachPage);
        try {
        const encodedData = JSON.stringify(dotsOfEachPage);
        const offlinestrokesavedata = {
            strokes: encodedData,
            patient_id: patientid
        };

        axios.post(`${Api.$offlineStrokesSave}/${usermapid}`, offlinestrokesavedata).then(
            response => {
                console.log(response);
            }).catch(errror => {
                console.log(errror);
            })

            const notes = '';

            const blob = dataURLToBlob(canvasUrl);

            console.log('Blob URL:', blob);

            const formData = new FormData();
            formData.append('upload_file',blob, 'test.jpg');
            formData.append('notes', notes);

            axios.post(`${Api.$prescriptionsavetos3bucket}/${usermapid}/${patientid}`, formData)
                .then(response => {
               //saving the updated stroke prescription
                const formdata = new FormData();
                formdata.append('upload_file', blob, 'test.jpg');
                formdata.append('pageId', pageId);

                 axios.post(`${Api.$uploadPrescription}/${usermapid}` , formdata)
                 .then(response=> {
                    console.log('updated the prescription responce' , response);
                 }).catch(error => {
                    console.error('Error :', error);
                })
              //
                    console.log('File URL:', response.data.file);
                    if (response.data.file) {
                        var data = {
                            "prescription_image": response.data.file,
                            "patient_id": patientid,
                            "booking_id": '',
                            "date": '',
                            "note": [],
                            "medication": '',
                            "vitals": '',
                            "systemic_examination": JSON.stringify([{
                                "cvc": '',
                                "ent": '',
                                "rs": 's',
                                "pa": 's',
                                "cns": '',
                                "gen": ''
                            }]),
                            "complaints": '',
                            "complaints_row": '',
                            "diagnosis": '',
                            "diagnosis_row": '',
                            "advice": ' ',
                            "test_requested": ' ',
                            "medical_history": '',
                            "lifestyle": '',
                            "follow_up": '',
                            "vaccination_details": '',
                            "clinic_id": '',
                        }
                        axios.post(`${Api.$prescriptionSave}/${usermapid}/prescription`, data).then(response => {
                            console.log(response);
                        }).catch(error => {
                            console.error('Error :', error);
                        })
                    }
                    if (response.data.file) {
                        var offlinedata = {
                            "prescription_image": response.data.file
                        }
                        axios.post(`${Api.$offlineSyncedPrescriptionSave}/${usermapid}/${patientid}`, offlinedata).then(response => {
                            console.log(response);
                        }).catch(error => {
                            console.error('Error :', error);
                        })
                    }
                })
                .catch(error => {
                    console.error('Error:', error);
                });
            console.log(Response);
            // const notes = '';
            // const blob =  canvasUrl;

            // console.log(blob);

            // console.log('Blob URL:', blob);

            // const formData = new FormData();
            // // formData.append('upload_file',blob, 'test.pdf');
            // formData.append('notes', notes);

            // axios.post(`${Api.$prescriptionsavetos3bucket}/${usermapid}/${patientid}`, formData)
            //     .then(response => {
            //         console.log('File URL:', response.data.file);
            //         if (response.data.file) {
            //             var data = {
            //                 "prescription_image": response.data.file,
            //                 "patient_id": patientid,
            //                 "booking_id": '',
            //                 "date": '',
            //                 "note": [],
            //                 "medication": '',
            //                 "vitals": '',
            //                 "systemic_examination": JSON.stringify([{
            //                     "cvc": '',
            //                     "ent": '',
            //                     "rs": 's',
            //                     "pa": 's',
            //                     "cns": '',
            //                     "gen": ''
            //                 }]),
            //                 "complaints": '',
            //                 "complaints_row": '',
            //                 "diagnosis": '',
            //                 "diagnosis_row": '',
            //                 "advice": ' ',
            //                 "test_requested": ' ',
            //                 "medical_history": '',
            //                 "lifestyle": '',
            //                 "follow_up": '',
            //                 "vaccination_details": '',
            //                 "clinic_id": '',
            //             }
            //             axios.post(`${Api.$prescriptionSave}/${usermapid}/prescription`, data).then(response => {
            //                 console.log(response);
            //             }).catch(error => {
            //                 console.error('Error :', error);
            //             })
            //         }
            //         if (response.data.file) {
            //             var offlinedata = {
            //                 "prescription_image": response.data.file
            //             }
            //             axios.post(`${Api.$offlineSyncedPrescriptionSave}/${usermapid}/${patientid}`, offlinedata).then(response => {
            //                 console.log(response);
            //             }).catch(error => {
            //                 console.error('Error :', error);
            //             })
            //         }
            //     })
            //     .catch(error => {
            //         console.error('Error:', error);
            //     });
            // console.log(Response);

        } catch (error) {
            console.error('Error:', error);
        }

    }
    }

    const downloadCanvasAsImage = () => {
         if (canvas) {
            try {
                const image = canvas.toDataURL('image/png');

                const link = document.createElement('a');
                link.href = image;
                link.download = 'Prescription.png';
                link.click();
                setCanvasUrl(link.href);
            } catch (error) {
                console.error('Failed to download canvas as image:', error);
            }
        } else {
            console.error('Canvas is not available.');
        }
    };

      useEffect(() => {
    const timeout = setTimeout(() => {
      save()
    }, 3000);
    return () => clearTimeout(timeout);
}, [canvasUrl]);
    return (
        <>

            <Button onClick={downloadCanvasAsImage} style={{ backgroundColor: '#1679AB', color: 'white', borderRadius: '4px', marginTop: '5px', marginBottom: '5px' }} variant="contained"><FontAwesomeIcon icon={faPlusCircle} className={classes.icon} />Save prescription</Button>
        </>
    );
};

export default DownloadCanvas;
