import { Button, colors, makeStyles, TextField } from "@material-ui/core";
import {
    PenHelper,
    NoteServer,
    PenMessageType,
    PenController,
} from "web_pen_sdk";
import React, { useCallback, useEffect, useReducer, useRef, useState } from "react";
import { fabric } from "fabric";
import {
    Dot,
    PageInfo,
    ScreenDot,
    PaperSize,
    VersionInfo,
    SettingInfo,
} from "web_pen_sdk/dist/Util/type";
import { NULL_PageInfo } from "../utils/constants";
import Header from "../component/Header";
import note_3138 from "../../assets/note_3138.nproj";
import alice from "../../assets/alice_Quiz03.nproj";
import axios from "axios";
import Api from "../../Api";


import { FaEye, FaRegCirclePause, FaShareNodes } from "react-icons/fa6";
import { FaRegPlayCircle } from "react-icons/fa";
import { RiArrowLeftDoubleFill } from "react-icons/ri";
import { RiArrowRightDoubleFill } from "react-icons/ri";
import { FaRegTrashCan } from "react-icons/fa6";
import { PiPencilCircle } from "react-icons/pi";
import { BiSolidEraser } from "react-icons/bi";
import { PiMarkerCircleBold } from "react-icons/pi";
import { PiPencilCircleFill } from "react-icons/pi";
import { ReactSketchCanvas, ReactSketchCanvasRef } from "react-sketch-canvas";
import ColorPickerButton from "../buttons/ColorPickerButton";
import PenWidthSelector from "../buttons/PenWidthSelector";
import { IoShareSocialSharp } from "react-icons/io5";
import { LuShare2 } from "react-icons/lu";
import { HiShare } from "react-icons/hi";
import DrawerList from "../buttons/DrawerList";
import ViewPrescriptionList from "../buttons/ViewPrecriptionList";
import PrescriptionSidebar from "../component/PrescriptionSidebar";
import PrescriptionPreview from "./PrescriptionPreview";
import { useNavigate } from "react-router-dom";



interface Stroke {
    Dots: Dot[];
}
interface OfflinePageData {
    Section: number;
    Owner: number;
    Note: number;
    Pages: number[];
}

const useStyle = makeStyles(() => ({
    mainBackground: {
        display: "flex",
        flexDirection: "column",
        textAlign: "center",
        alignItems: "center",
        position: "relative",
        // height: window.innerHeight - 163.25,
        height: '578px',
        justifyContent: "center",
        // backgroundColor: "#f5f5f5",
    },
    hoverCanvasContainer: {
        position: "absolute",
    },
    mainCanvas: {
        position: "absolute",
        boxShadow: "1px 2px 6px rgba(0, 0, 0, 0.2)",
    },
    hoverCanvas: {
        position: "absolute",
    },
    inputContainer: {
        display: "flex",
        justifyContent: "center",
    },
    inputStyle: {
        margin: 20,
    },
    menu: {
        display: 'flex',
        alignItems: 'center',
        backgroundColor: 'white',
        padding: '10px',
        width: '100%',
        // overflow: 'auto',
        // flexWrap: 'wrap',
    },
    menuButton: {
        color: 'white',
        margin: '0 10px',
        display: 'flex'
    },
    textField: {
        color: 'black',
        '& input': {
            color: 'black',
        },
        fontSize: '15px',
        // fontWeight:'bold'
        padding: '5px',
        margin: '5px'

    },
    select: {
        color: 'black',
    },
    icon: {
        color: 'black',
        margin: '0px',
        fontSize: '24px',
        borderRadius: '50%'
    },
    iconButton: {
        borderRadius: '60%',
        padding: '15px'
    },


    zoomLevelInput: {
        width: '70px',
        display: 'block',
        margin: '0px',
        padding: '4px',
    },
    selected: {
        backgroundColor: '#cceeff',
    },
    active: {
        backgroundColor: '#cceeff',
        padding: '3px'

    }

}));


const PenBasic = ({ usermapid = '', patientid = '' }: { usermapid: string | undefined, patientid: string | undefined }) => {
    console.log('id got', usermapid, patientid);
    const classes = useStyle();

    const [canvasFb, setCanvasFb] = useState<any>();
    const [hoverCanvasFb, setHoverCanvasFb] = useState<any>();
    const [ctx, setCtx] = useState<any>();

    const [isPageReady, setIsPageReady] = useState<boolean>(false);
    const pendingDotsRef = useRef<Dot[]>([]);
    const pageReadyEventRef = useRef(new EventTarget());

    const [imageChangeCount, setImageChangeCount] = useState<number>(0);
    const [noteWidth, setNoteWidth] = useState<number>(0);
    const [noteHeight, setNoteHeight] = useState<number>(0);

    const [hoverPoint, setHoverPoint] = useState<any>();
    const [angle, setAngle] = useState<number>(0);

    const [plateMode, setPlateMode] = useState<boolean>(false);
    const [passwordPen, setPasswordPen] = useState<boolean>(false);
    const [penVersionInfo, setPenVersionInfo] = useState<VersionInfo>();
    const [penSettingInfo, setPenSettingInfo] = useState<SettingInfo>();
    const [controller, setController] = useState<PenController>();

    const [authorized, setAuthorized] = useState<boolean>(false);

    const [ignored, forceUpdate] = useReducer((x) => x + 1, 0);
    const offlineDataRef = useRef<Stroke[] | null>(null);
    const offlineDataReceiveCompletedRef = useRef<boolean>(false);
    const [offlineDataDrawing, setOfflineDataDrawing] = useState<boolean>(false);

    const [isDrawingPaused, setIsDrawingPaused] = useState<boolean>(false);
    const [groupedOfflineData, setGroupedOfflineData] = useState<Map<string, Dot[]>>(new Map());
    const currentGroupIndex = useRef<number>(0);

    const [currentPageInfo, setCurrentPageInfo] = useState<PageInfo | null>(null);
    const [isBackgroundImageSet, setIsBackgroundImageSet] = useState<boolean>(false);
    const [imageBlobUrl, setImageBlobUrl] = useState<any>();
    const [paperSize, setPaperSize] = useState<any>(null);

    const currentPageInfoRef = useRef<PageInfo | null>(null);
    const isBackgroundImageSetRef = useRef(false);
    const imageBlobUrlRef = useRef(null);
    const paperSizeRef = useRef(null);

    const [fetcheddata, setFetchedData] = useState([]);
    const [dotsOfEachPage, setDots] = useState<any>([]);

    const [pageIdd, setpageIdd] = useState<any>();
    const [canvasUrl, setCanvasUrl] = useState<string | null>(null);
    const [pageidOfflinee, setpageIdForOffline] = useState<any>();
    const [pageref, setPageRef] = useState<any>();
    const [selectedPen, setSelectedPen] = useState('');
    const [penRenderType, setPenRendererType] = useState<number>(0);
    const [fetchedFlag, setFetchedFlag] = useState(false);
    const [enddrawaing, setenddrawing] = useState(false);
    const [pageInfo, setPageInfo] = useState<PageInfo>();
    const [isPageChange, setPageChange] = useState<PageInfo>();

    const [shareurl, setCanvasShareUrl] = useState<any>();

    useEffect(() => {
        const { canvas, hoverCanvas } = createCanvas();
        setCanvasFb(canvas);
        setHoverCanvasFb(hoverCanvas);
    }, []);

    useEffect(() => {
        isBackgroundImageSetRef.current = isBackgroundImageSet;
    }, [isBackgroundImageSet]);

    useEffect(() => {
        imageBlobUrlRef.current = imageBlobUrl;
    }, [imageBlobUrl]);

    useEffect(() => {
        paperSizeRef.current = paperSize;
    }, [paperSize]);

    useEffect(() => {
        currentPageInfoRef.current = currentPageInfo;
    }, [currentPageInfo]);

    useEffect(() => {
        if (isBackgroundImageSet && imageBlobUrl && paperSize) {
            setIsPageReady(true);
            pageReadyEventRef.current.dispatchEvent(new Event('pageReady'));
        } else {
            setIsPageReady(false);
        }
    }, [isBackgroundImageSet, imageBlobUrl, paperSize]);

    const waitForPageReady = () => {
        return new Promise<void>((resolve) => {
            if (isPageReady) {

                resolve();
            } else {
                pageReadyEventRef.current.addEventListener('pageReady', () => resolve(), { once: true });
            }
        });
    };

    // useEffect(() => {
    //     console.log('shareUrl' , shareurl);
    //     shareCanvaslogic();
    //   }, [shareurl]);

    const pageIdRef = useRef(pageIdd);

    async function getNoteImageUsingAPI(pageInfo) {
        console.log('pageinffffffingetimageUsingAPI', pageInfo.page);
        if (PenHelper.isPUI(pageInfo)) {
            return;
        }
        if (pageInfo.section === 0) {
            // pageInfo.section === 0 -> abnormal pageInfo
            return;
        }
        try {
            const image = new Image();
            image.setAttribute('crossOrigin', 'anonymous');
            // await NoteServer.getNoteImage(pageInfo, setImageBlobUrl);
            // image.src = 'https://makebot-v3.s3.ap-south-1.amazonaws.com/GeniePrescription/GeniePrescription1723186856.png';
            const pageId = pageInfo.page;
            setpageIdd(pageId);
            axios.get(`${Api.$getGeniePrescription}/${usermapid}/${pageId}`).then(
                async response => {
                    console.log('resssssss', response.data.data);
                    if (response.data) {
                        const imageUrl = response.data.data
                        const base64Data = await convertImageUrlToBase64(imageUrl);
                        image.src = base64Data;
                        setImageBlobUrl(image.src);
                    } else {
                        await NoteServer.getNoteImage(pageInfo, setImageBlobUrl);
                    }
                    console.log(response);
                }).catch(errror => {
                    console.log(errror);
                });


            let paperSize: any = {};
            // paperSize.Xmax = 82.66309523809522;
            // paperSize.Xmin = 3.4976041666666666;
            // paperSize.Ymax = 111.82976190476188;
            // paperSize.Ymin = 3.4976190476190476;

            paperSize.Xmax = 87.66309523809522;
            paperSize.Xmin = 1.2176041666666666;
            paperSize.Ymax = 125.52976190476188;
            paperSize.Ymin = 1.6876190476190476;

            setPaperSize(paperSize);
        } catch (e) {
            console.log(e);
        }

        let nprojUrl: string | null = null;
        if (pageInfo.book && pageInfo.book === 3138) {
            nprojUrl = note_3138;
        } else if (pageInfo.owner === 45 && pageInfo.book === 3) {
            nprojUrl = alice;
        }
        try {
            // await NoteServer.setNprojInPuiController(nprojUrl, pageInfo);

            //   const paperSize = (await NoteServer.extractMarginInfo(
            //     nprojUrl,
            //     pageInfo
            //   )) as any;
            // setPaperSize(paperSize);



            // let paperSize: any = {};
            // paperSize.Xmax = 82.66309523809522;
            // paperSize.Xmin = 3.4976041666666666;
            // paperSize.Ymax = 111.82976190476188;
            // paperSize.Ymin = 3.4976190476190476;

            // console.log('paperrrrsizeeee', paperSize);
            // setPaperSize(paperSize);
        } catch (e) {
            console.log(e);
        } finally {
            console.log("success");
        }

        // Refresh the PUI settings every time the page changes . because the PUI location may be different each page

        if (PenHelper.isPlatePaper(pageInfo)) {
            // SmartPlate Case, Since we do not use the image retrived from the server , set it to , adn set the background color OF canvasFb white
            setImageBlobUrl(0);
            canvasFb.backgroundColor = "white";
            canvasFb.renderAll();
            setIsBackgroundImageSet(true);
        }
    }

    async function convertImageUrlToBase64(url: string): Promise<string> {
        const response = await fetch(url);
        const blob = await response.blob();
        const reader = new FileReader();

        return new Promise((resolve, reject) => {
            reader.onloadend = () => {
                const base64String = reader.result as string;
                resolve(base64String);
            };
            reader.onerror = reject;
            reader.readAsDataURL(blob);
        });
    }

    const setCurrentPageInfoAndPrepare = async (newPageInfo: PageInfo) => {
        console.log('pageInfoooo to displayyy', newPageInfo, newPageInfo.page);
        setpageIdd(newPageInfo.page);
        setIsBackgroundImageSet(true);
        setImageBlobUrl(null);
        setPaperSize(paperSize);
        setCurrentPageInfo(newPageInfo);

        await new Promise<void>(resolve => setTimeout(resolve, 0));

        await getNoteImageUsingAPI(newPageInfo);

        await waitForPageReady();

        setIsDrawingPaused(false);
    };


    useEffect(() => {
        if (hoverCanvasFb) {
            createHoverPoint();
        }
    }, [hoverCanvasFb]);


    /** Get noteImage size */
    useEffect(() => {
        if (imageBlobUrl) {
            console.log('imaaaaageee');
            const image = new Image();
            image.src = imageBlobUrl;
            image.crossOrigin = 'anonymous';
            image.onload = async () => {
                await loadImageWithCrossOrigin(imageBlobUrl);
                setNoteWidth(image.width);
                setNoteHeight(image.height);
            };
            setImageChangeCount(imageChangeCount + 1);
        }
    }, [imageBlobUrl]);


    const loadImageWithCrossOrigin = (url) => {
        return new Promise((resolve, reject) => {
            const image = new Image();
            image.crossOrigin = 'anonymous'; // Ensure cross-origin is handled
            image.onload = () => resolve(image);
            image.onerror = () => reject(new Error(`Could not load image at ${url}`));
            image.src = url;
        });
    };

    useEffect(() => {
        if (noteWidth > 0 && noteHeight > 0) {
            if (currentPageInfoRef.current && currentPageInfoRef.current.section === 0) {
                // pageInfo.section === 0 -> abnormal pageInfo
                return;
            }

            if (currentPageInfoRef.current && PenHelper.isPlatePaper(currentPageInfoRef.current)) {
                // In case of SmartPlate, not required bottom process.
                return;
            }

            if (imageBlobUrl === 0) {
                // In case of 'imageBlobUrl === 0', not required bottom process.
                return;
            }

            setCanvasInitialize();

            /**
             * Refactoring canvas width based on noteImage.
             *
             * Canvas(View) default height = 'window.innerHeight - 81(Header) - 82.25(input container)'
             * CanvasFb.height : CanvasFb.width = noteHeight : noteWidth;
             * CanvasFb.width(=refactorCanvasWidth) = (CanvasFb.height * noteWidth) / noteHeight;
             */
            const refactorCanvasWidth = (canvasFb.height * noteWidth) / noteHeight;
            canvasFb.setWidth(refactorCanvasWidth);
            hoverCanvasFb.setWidth(refactorCanvasWidth);

            canvasFb.setBackgroundImage(
                imageBlobUrl,
                () => {
                    console.log('set background image ', imageBlobUrl);
                    canvasFb.renderAll();
                    //   imageBlobUrl.crossOrigin = 'anonymous';
                    setIsBackgroundImageSet(true);
                },
                {
                    // Resizing noteImage to fit canvas size
                    scaleX: canvasFb.width / noteWidth,
                    scaleY: canvasFb.height / noteHeight,
                    // backgroundImage angle setting
                    angle: angle,
                    top: [180, 270].includes(angle) ? canvasFb.height : 0,
                    left: [90, 180].includes(angle) ? canvasFb.width : 0,
                }
            );
        }
    }, [noteWidth, noteHeight, angle, imageChangeCount]);

    useEffect(() => {
        if (isBackgroundImageSet && paperSizeRef.current && pendingDotsRef.current.length > 0) {
            processPendingDots();
        }
    }, [isBackgroundImageSet, paperSize]);

    useEffect(() => {
        const groupOfflineDataByPageInfo = (offlineData: Stroke[]) => {
            // debugger;
            const grouped = new Map<string, Dot[]>();
            offlineData.forEach(stroke => {
                stroke.Dots.forEach(dot => {
                    const key = `${dot.pageInfo.section}-${dot.pageInfo.owner}-${dot.pageInfo.book}-${dot.pageInfo.page}`;
                    if (!grouped.has(key)) {
                        grouped.set(key, []);
                    }
                    grouped.get(key)!.push(dot);
                });
            });
            return grouped;
        };

        if (offlineDataRef.current && !offlineDataDrawing) {
            // debugger;
            const grouped = groupOfflineDataByPageInfo(offlineDataRef.current);
            setGroupedOfflineData(grouped);
            currentGroupIndex.current = 0;
        }
    }, [offlineDataRef.current]);

    useEffect(() => {
        console.log('inprocessNextGroupiddd', pageIdd, pageidOfflinee, currentGroupIndex.current >= groupedOfflineData.size);
        const processNextGroup = async () => {
            if (currentGroupIndex.current >= groupedOfflineData.size) {
                console.log('setofllineDataDrawing false');
                // await call();
                setOfflineDataDrawing(false);
                return;
            }

            const currentGroup = Array.from(groupedOfflineData.entries())[currentGroupIndex.current];
            const [pageInfoKey, dots] = currentGroup;

            setIsDrawingPaused(true);
            console.log('dppp', dots[0].pageInfo);
            await setCurrentPageInfoAndPrepare(dots[0].pageInfo);

            console.log('offline data dotssss', dots, dots[0]);

            for (const dot of dots) {
                await new Promise<void>(resolve => {
                    strokeProcess(dot);
                    setTimeout(resolve, 10);

                });
            }
            await calll(dots[0].pageInfo);
            currentGroupIndex.current++;
            processNextGroup();

        };

        if (offlineDataDrawing && groupedOfflineData.size > 0) {
            console.log('processing the next group', groupedOfflineData, pageIdd);
            processNextGroup();

        }


    }, [offlineDataDrawing, groupedOfflineData]);



    const drawingOffline = async () => {
        console.log('start drwing');
        setOfflineDataDrawing(true);
    }

    /**
     * This callback type is called `dotCallback`.
     *
     * @callback dotCallback
     */
    useEffect(() => {
        PenHelper.dotCallback = async (mac, dot) => {
            strokeProcess(dot);
            console.log('dot that recieved on callbackk', dot);
            setDots(prevDots => [...prevDots, dot]);
        };
    });
    const [drawingColor, setDrawingColor] = useState("#000000"); // Default color



    /**
     * This callback type is called `messageCallback`. (Pen Event Callback)
     *
     * @callback messageCallback
     */
    useEffect(() => {
        PenHelper.messageCallback = async (mac, type, args) => {
            messageProcess(mac, type, args);
        };
    });

    /** Create mainCanvas, hoverCanvas */
    const createCanvas = () => {
        const canvas = new fabric.Canvas("mainCanvas");
        const hoverCanvas = new fabric.Canvas("hoverCanvas");

        setCtx(canvas.getContext());
        return { canvas, hoverCanvas };
    };

    // useEffect(() => {
    //     ctx.strokeStyle = drawingColor; // For lines/outlines
    //     ctx.fillStyle = drawingColor;
    // },[ctx]);



    const processPendingDots = () => {
        pendingDotsRef.current.forEach((dot) => processDot(dot));
        pendingDotsRef.current = [];
    };





    /**
     * Process pending dot after pageInfo is updated.
     *
     * @param {Dot} dot
     */
    const processDot = (dot: Dot) => {
        // debugger;
        console.log('dot in the processDot', dot);
        if (!isBackgroundImageSetRef.current || !imageBlobUrlRef.current || !paperSizeRef.current) {
            pendingDotsRef.current.push(dot);
            return;
        }

        /** Convert SmartPlate ncode dot coordinate values ​​according to the view size */
        const view = { width: canvasFb.width, height: canvasFb.height };
        let screenDot: ScreenDot;
        if (PenHelper.isPlatePaper(dot.pageInfo)) {
            // Smart Plate
            screenDot = PenHelper.ncodeToScreen_smartPlate(
                dot,
                view,
                angle,
                paperSizeRef.current
            );

        } else {
            // Default
            screenDot = PenHelper.ncodeToScreen(dot, view, paperSizeRef.current);
        }

        try {
            const colorr = selectedTool === 'Eraser' ? '#fff' : color;
            ctx.fillStyle = colorr;
            ctx.strokeStyle = colorr;
            // ctx.fillRect(50, 50, 100, 100);
            if (dot.dotType === 0) {
                // Pen Down
                ctx.beginPath();
                hoverPoint.set({ opacity: 0 }); // In case of PenDown, hoverPoint dont need to look
                hoverCanvasFb.requestRenderAll();
            } else if (dot.dotType === 1) {
                // Pen Move

                ctx.lineWidth = penWidth;
                ctx.lineJoin = 'round';
                ctx.lineCap = 'round';
                ctx.lineTo(screenDot.x, screenDot.y);
                ctx.stroke();
                ctx.closePath();
                ctx.beginPath();
                ctx.moveTo(screenDot.x, screenDot.y);
            } else if (dot.dotType === 2) {
                // Pen Up
                ctx.closePath();
            } else if (dot.dotType === 3) {
                // Hover
                if(colorr==='#fff'){
                ctx.lineWidth = penWidth;
                ctx.lineJoin = 'round';
                ctx.lineCap = 'round';
                ctx.lineTo(screenDot.x, screenDot.y);
                ctx.stroke();
                ctx.closePath();
                ctx.beginPath();
                ctx.moveTo(screenDot.x, screenDot.y);
                }
                ctx.closePath();
                hoverProcess(screenDot);
            }
        } catch {
            console.log("ctx : " + ctx);
        }
    };

    function dataURLToBlob(dataURL: string): Blob {
        const arr = dataURL.split(',');
        const mime = arr[0].match(/:(.*?);/)?.[1] || '';
        const bstr = atob(arr[1]);
        let n = bstr.length;
        const u8arr = new Uint8Array(n);
        while (n--) {
            u8arr[n] = bstr.charCodeAt(n);
        }
        return new Blob([u8arr], { type: mime });
    }

    const save = (can) => {
        console.log('canvassssurlllll', canvasUrl, canvas);
        const notes = '';
        const blob = dataURLToBlob(can);
        const formdata = new FormData();
        formdata.append('upload_file', blob, 'test.pdf');
        console.log('pageeeeifff in the saveee in save ', pageIdd);
        formdata.append('pageId', pageIdd);

        axios.post(`${Api.$uploadPrescription}/${usermapid}`, formdata)
            .then(response => {
                console.log('updated the prescription responce', response);

            }).catch(error => {
                console.error('Error :', error);
            })
    }

    const savee = (can, page) => {
        console.log('canvassssurlllll', canvasUrl, canvas);
        const notes = '';
        const blob = dataURLToBlob(can);
        console.log('Blob URL:', blob);
        //saving the updated stroke prescription
        const formdata = new FormData();
        formdata.append('upload_file', blob, 'test.pdf');
        console.log('pageeeeifff in the saveee in save ', page);
        formdata.append('pageId', page);

        axios.post(`${Api.$uploadPrescription}/${usermapid}`, formdata)
            .then(response => {
                console.log('updated the prescription responce', response);

            }).catch(error => {
                console.error('Error :', error);
            })
        // }

    }

    async function handlePageChange(dot: Dot) {
        if (
            (!currentPageInfoRef.current && !PenHelper.isSamePage(dot.pageInfo, NULL_PageInfo)) ||
            (currentPageInfoRef.current && !PenHelper.isSamePage(currentPageInfoRef.current, dot.pageInfo))
        ) {
            console.log('offlinecase page changeeeeeeeeeeeeeee', dot);
            setIsDrawingPaused(true);
            console.log('pageiiiiiiinfopageeeee', dot.pageInfo.page);
            setCurrentPageInfoAndPrepare(dot.pageInfo);

            console.log('handle pge change', dot.pageInfo.page);
            console.log('get getStoredDataaforpageChange', dot.pageInfo.page);

            const encodedData = JSON.stringify(dotsOfEachPage);
            console.log('post the strokeeeeee');

            const offlinestrokesavedata = {
                strokes: encodedData,
                patient_id: patientid,
            };

            // Handle the canvas data and update the prescription
            await call();
        }
    }

    async function call() {
        console.log('cancaaaa' , canvas);
            if (canvas) {
            console.log('calledddd on the page changeeeeeeeeeeeeeeeeeeeeeeeeeeeee2', canvas, canvasUrl);
            const image = canvas.toDataURL('image/png');
            const link = document.createElement('a');
            link.href = image;
            await setCanvasUrl(link.href);
            console.log('link.hrefffff', pageidOfflinee);
            await save(image);
            console.log('Canvas data processed and saved successfully.');
        } else {
            console.error('Canvas is not available.');
        }
    }


    async function calll(pageInfo) {
        console.log('cancaaaa1' , canvas);
        if (canvas) {
            console.log('calledddd on the page changeeeeeeeeeeeeeeeeeeeeeeeeeeeee1', canvas, canvasUrl);
            const image = canvas.toDataURL('image/png');
            const link = document.createElement('a');
            // link.download = 'Prescription.png';
            link.href = image;
            // link.click();
            await setCanvasUrl(link.href);
            console.log('link.hrefffff', pageidOfflinee);
            await savee(image, pageInfo.page);
            console.log('Canvas data processed and saved successfully.');
        } else {
            console.error('Canvas is not available.');
        }
    }

    /**
     * Process ncode dot.
     *
     * @param {Dot} dot
     */
    const strokeProcess = async (dot: Dot) => {
        // debugger;
        console.log('ccurrentPageInfoRef.current', currentPageInfoRef.current, dot.pageInfo.page)

        if (PenHelper.isPlatePaper(dot.pageInfo) && !plateMode) {
            // disable use if smartplate is touched and platemode is not set to on
            if (dot.dotType === 0) {
                // Show alert message only if penDown
                alert("After setting plate mode to on, Please reset the canvas");
            }
            return;
        }
        // getnoteima
        setpageIdd(dot.pageInfo.page);
        console.log(
            `s: ${currentPageInfoRef.current?.section}, o: ${currentPageInfoRef.current?.owner}, b: ${currentPageInfoRef.current?.book}, p: ${currentPageInfoRef.current?.page}`
        );
        console.log('pageeinfof set pageId', dot.pageInfo, dot.pageInfo.page);


    //  call();
        if (PenHelper.isSamePage(dot.pageInfo, NULL_PageInfo)) {
            return;
        } else {
            console.log('setfetchedddflag false means page chnage');
            setFetchedFlag(false);
        }
        if (isDrawingPaused) {
            pendingDotsRef.current.push(dot);
            return;
        }

        /** Update pageInfo either pageInfo !== NULL_PageInfo or pageInfo changed */
        if ((!currentPageInfoRef.current && !PenHelper.isSamePage(dot.pageInfo, NULL_PageInfo)) ||
            (currentPageInfoRef.current && !PenHelper.isSamePage(currentPageInfoRef.current, dot.pageInfo))) {
            await getNoteImageUsingAPI(dot.pageInfo);
            await handlePageChange(dot);

            setIsDrawingPaused(true);
            setCurrentPageInfoAndPrepare(dot.pageInfo);
            console.log('get getStoredDataaforpageChange', dot.pageInfo.page);

            const encodedData = JSON.stringify(dotsOfEachPage);
            console.log('post the strokeeeeee');
            const offlinestrokesavedata = {
                strokes: encodedData,
                patient_id: patientid
            };

        }

        console.log('!isBackgroundImageSetRef.current || imageBlobUrlRef.current === undefined || !paperSizeRef.current', !isBackgroundImageSetRef.current, imageBlobUrlRef.current === undefined, !paperSizeRef.current)

        if (!isBackgroundImageSetRef.current || imageBlobUrlRef.current === undefined || !paperSizeRef.current) {
            pendingDotsRef.current.push(dot);
            return;
        }
        processDot(dot);
    }

    let resolveOfflineDataPromise: {
        resolve: (value: Stroke[]) => void;
        reject: (reason?: any) => void;
    } | null = null;
    let resolveOfflinePageListPromise:
        | ((value: OfflinePageData | PromiseLike<OfflinePageData>) => void)
        | null = null;
    const requestOfflinePageList = (
        Section: number,
        Owner: number,
        Note: number
    ) => {
        if (!controller) {
            return Promise.reject(new Error("Controller not initialized"));
        }

        return new Promise<OfflinePageData>((resolve, reject) => {
            resolveOfflinePageListPromise = resolve;
            controller.RequestOfflinePageList(Section, Owner, Note);
        });
    };
    const requestOfflineData = (Section: number, Owner: number, Note: number) => {
        // debugger;
        if (!controller) {
            return Promise.reject(new Error("Controller not initialized"));
        }

        return new Promise<Stroke[]>((resolve, reject) => {
            resolveOfflineDataPromise = { resolve, reject };
            controller.RequestOfflineData(Section, Owner, Note, true, []);
        });
    };

    const downloadFile = (filename: string, data: any) => {
        const blob = new Blob([JSON.stringify(data, null, 2)], { type: 'application/json' });
        const link = document.createElement('a');
        link.href = URL.createObjectURL(blob);
        link.download = filename;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    };

    const [cheackofflinedataready, setcheackofflinedataready] = useState<boolean>(false);

    const getOfflineData = async (
        bookData: { Section: number; Owner: number; Note: number }[]
    ) => {
        if (!bookData.length || !controller) {
            return;
        }

        for (let i = 0; i < bookData.length; i++) {
            const { Section: s, Owner: o, Note: n } = bookData[i];
            const offlinePageList = (await requestOfflinePageList(
                s,
                o,
                n
            )) as OfflinePageData;

            if (offlinePageList) {
                // debugger
                const { Section: sp, Owner: op, Note: no, Pages: p } = offlinePageList;
                console.log(`take offline data: ${sp}, ${op}, ${no}, ${p}`);
                try {
                    const result = await requestOfflineData(sp, op, no);
                    console.log("requestOfflineData result: ", result);

                    offlineDataRef.current = result;
                    downloadFile('result.json', result);

                    //   save the offline data
                    console.log('offffffflie', offlineDataRef.current.length);
                    if (offlineDataRef.current.length) {
                        setcheackofflinedataready(true);
                    }
                    for (let i = 0; i < offlineDataRef.current.length; i++) {
                        console.log('ooooo', offlineDataRef.current[i]);
                        const dataObject = offlineDataRef.current[i];
                        const dotsArray = dataObject.Dots;
                        const encodedData = JSON.stringify(dotsArray);

                        const offlinestrokesavedata = {
                            strokes: encodedData,
                            patient_id: patientid
                        };
                    }
                    forceUpdate();
                } catch (e) {
                    console.log("requestOfflineData error: " + e);
                }
            }
        }
    };

    const shareCanvas = () => {
        const canvas = mainCanvasRef.current;
            axios.get(`${Api.$getGeniePrescription}/${usermapid}/${pageIdd}`).then(
                async response => {
                   if(response.data.data){
                    const shareurl = response.data.data;
                    setCanvasShareUrl(shareurl);
                    shareCanvaslogic(shareurl);
                   }
                }).catch(errror => {
                    console.log(errror);
                });
    };

    const shareCanvaslogic = async (shareurl:any) => {
        if (shareurl) {
            const response = await fetch(shareurl);
            const blob = await response.blob();
            const file = new File([blob], 'prescription.jpg', {type: blob.type});
            console.log('sharable file ',file);
            if(navigator.share) {
                console.log('navigator.share',navigator.share);
              await navigator.share({
                title: "title",
                text: "your text",
                url: "url to share",
                files: [file]
              })
                .then(() => console.log('Successful share'))
                .catch((error) => console.log('Error in sharing', error ));
            }else {
              console.log(`system does not support sharing files.`);
            }

        } else {
            console.error("Canvas share URL is not available.");
        }
    };

    const offlineDataInit = () => {
        offlineDataRef.current = [];
        offlineDataReceiveCompletedRef.current = false;
    }

    /**
     * Message callback process. (Pen Event Processing)
     *
     * @param mac
     * @param type
     * @param args
     */
    const messageProcess = (mac, type, args) => {
        // console.log(mac, type, args);

        switch (type) {
            case PenMessageType.PEN_SETTING_INFO:
                const _controller = PenHelper.pens.filter(
                    (c) => c.info.MacAddress === mac
                )[0];
                setController(_controller);
                setPenSettingInfo(args);
                setPenVersionInfo(_controller.RequestVersionInfo());
                break;
            case PenMessageType.PEN_SETUP_SUCCESS:
                // debugger;
                controller?.RequestPenStatus();
                console.log('success', PenMessageType.PEN_SETUP_SUCCESS);
                // getStoredData().then(result => {
                //     console.log('Return value:', result);
                // });
                break;
            case PenMessageType.PEN_DISCONNECTED:
                console.log("Pen disconnted");
                setController(undefined);
                setPenVersionInfo(undefined);
                setPenSettingInfo(undefined);
                setAuthorized(false);
                break;
            case PenMessageType.PEN_PASSWORD_REQUEST:
                setPasswordPen(true);
                onPasswordRequired(args);
                break;
            case PenMessageType.PASSWORD_SETUP_SUCCESS:
                const usingPassword = args.UsingPassword;
                if (usingPassword) {
                    setPasswordPen(true);
                } else {
                    setPasswordPen(false);
                }
                break;
            case PenMessageType.PEN_AUTHORIZED:
                setAuthorized(true);
                PenHelper.debugMode(false);
                break;
            case PenMessageType.PEN_USING_NOTE_SET_RESULT:
                controller?.SetHoverEnable(true);
                break;
            case PenMessageType.EVENT_DOT_PUI:
                console.log(args);
                break;
            case PenMessageType.OFFLINE_DATA_NOTE_LIST:
                const offlineNoteList = args;
                if (!offlineNoteList.length) {
                    alert("There is no offline note list.");
                    return;
                }
                const dataString = JSON.stringify(offlineNoteList, null, 2);
                // eslint-disable-next-line no-restricted-globals
                const result = confirm(
                    `The list of the offline notes is as follows.\n\n ${dataString} \n\n Would you like to retrive offline data?`
                );
                if (result) {
                    getOfflineData(offlineNoteList);
                }
                break
            case PenMessageType.OFFLINE_DATA_PAGE_LIST:
                const offlinePageList = args;
                if (resolveOfflinePageListPromise) {
                    resolveOfflinePageListPromise(offlinePageList);
                    resolveOfflinePageListPromise = null;
                }
                break;
            case PenMessageType.OFFLINE_DATA_SEND_START:
                offlineDataInit();
                break;
            case PenMessageType.OFFLINE_DATA_SEND_STATUS:
                if (args === 100) {
                    offlineDataReceiveCompletedRef.current = true;
                }
                break;
            case PenMessageType.OFFLINE_DATA_SEND_SUCCESS:
            case PenMessageType.OFFLINE_DATA_SEND_FAILURE:
                if (resolveOfflineDataPromise) {
                    if (type === PenMessageType.OFFLINE_DATA_SEND_SUCCESS) {
                        offlineDataRef.current?.push(...args);
                        if (offlineDataReceiveCompletedRef.current) {
                            resolveOfflineDataPromise.resolve(offlineDataRef.current as Stroke[]);
                            resolveOfflineDataPromise = null;
                        }
                    } else {
                        resolveOfflineDataPromise.reject(args);
                        resolveOfflineDataPromise = null;
                    }
                }
                break;
            default:
                break;
        }
    };

    /**
     * Request Password Process.
     *
     * @param args
     */
    const onPasswordRequired = (args: SettingInfo) => {
        const password = prompt(
            `Please enter your password. (4 digit) (${args.RetryCount} attempts)\nPassword ${args.ResetCount}If an error occures ResetCount }times , the handwritting data is initialized. `
        );
        if (password === null) return;

        if (password.length !== 4) {
            alert("The password is 4 digits long.");
        }

        if (args.RetryCount >= 10) {
            alert("All pen information will be initialized.");
        }

        controller?.InputPassword(password);
    };

    /**
     * Set canvas angle.
     *
     * @param {number} rotate
     */
    const setCanvasAngle = (rotate: number) => {
        if (![0, 90, 180, 270].includes(rotate)) return;
        if (!currentPageInfoRef.current || !PenHelper.isPlatePaper(currentPageInfoRef.current)) return;

        if (
            Math.abs(angle - rotate) / 90 === 1 ||
            Math.abs(angle - rotate) / 90 === 3
        ) {
            // 90', 270' - swap noteWidth <-> noteHeight
            const tmp = noteWidth;
            setNoteWidth(noteHeight);
            setNoteHeight(tmp);
        }
        setAngle(rotate);
    };

    /**
     * Set canvas width.
     *
     * @param {number} width
     */
    const setCanvasWidth = (width: number) => {
        canvasFb.setWidth(width);
        hoverCanvasFb.setWidth(width);
        canvasFb.setBackgroundImage(0, canvasFb.renderAll.bind(canvasFb));
    };

    /**
     * Set canvas height.
     *
     * @param {number} height
     */
    const setCanvasHeight = (height: number) => {
        canvasFb.setHeight(height);
        hoverCanvasFb.setHeight(height);
        canvasFb.setBackgroundImage(0, canvasFb.renderAll.bind(canvasFb));
    };

    /** Initialize Canvas width, height, angle and plateMode */
    const setCanvasInitialize = () => {
        setPlateMode(false);
        canvasFb.setHeight(window.innerHeight - 163.25 - 20); // header(81) + inputContainer(82.25) + margin value(20)
        hoverCanvasFb.setHeight(window.innerHeight - 163.25 - 20);
        setAngle(0);
    };

    /**
     * Hover Point Process.
     *
     * @param {ScreenDot} screenDot
     */
    const hoverProcess = (screenDot: ScreenDot) => {
        hoverPoint.set({ left: screenDot.x, top: screenDot.y, opacity: 0.5 });
        hoverCanvasFb.requestRenderAll();
    };

    const createHoverPoint = () => {
        const hoverPoint = new fabric.Circle({
            radius: 2,
            fill: "#ff2222",
            stroke: "#ff2222",
            opacity: 0,
            top: 0,
            left: 0,
        });

        setHoverPoint(hoverPoint);
        hoverCanvasFb.add(hoverPoint);
    };


    async function getStoredData() {
        // get the strokes of the doctor
        // debugger;
        await axios.get(`${Api.$getoffflineSavedStrokes}/${usermapid}/${patientid}/${pageIdd}`).then(
            result => {
                const d1 = result.data.data[0];
                let strokesArray = JSON.parse(d1['strokes'])
                const d2 = strokesArray;
                setFetchedData(d2);
                setFetchedFlag(false);
            }).catch(error => {
                console.log('error', error);
                return 0
            })
    }

    async function getStoredDataaforpageChange(pageId) {
        // get the strokes of the doctor
        // debugger;
        console.log('getstoreddatafor the page change', pageId);
        await axios.get(`${Api.$getoffflineSavedStrokes}/${usermapid}/${patientid}/${pageId}`).then(
            result => {
                const d1 = result.data.data[0];
                console.log('strrrrrr', d1);
                let strokesArray = JSON.parse(d1['strokes'])
                const d2 = strokesArray;
                setFetchedData(d2);
                setFetchedFlag(false);
                // getStoredData();
                console.log('resultoffffff', d2, result.data.data);
            }).catch(error => {
                console.log('error', error);
                return 0
            })
    }

    const mainCanvasRef = useRef<HTMLCanvasElement>(null);
    const canvas = mainCanvasRef.current;

    const downloadCanvas = () => {
        const canvas = mainCanvasRef.current;
        if (canvas) {
            const image = canvas.toDataURL('image/png');
            const link = document.createElement('a');
            link.href = image;
            link.download = 'canvas-image.png';
            // link.click();
        }
    };


    const sketchCanvasRef = useRef<ReactSketchCanvasRef>(null);

    const [color, setColor] = useState('#000000');

    const handleColorChange = (selectedColor) => {
        setColor(selectedColor);
    };

    const [penWidth, setPenWidth] = useState(2);

    const handleWidthChange = (newWidth) => {
        setPenWidth(newWidth);
        handleToolChange('Pen')
    };

    const [selectedTool, setSelectedTool] = useState('Pen');

    const handleToolChange = (tool) => setSelectedTool(tool);
    const navigate = useNavigate(); // Initialize navigate

    const handleGoToView = () => {
        console.log('presss' ,isSetPrescriptionFlag )
        if(isSetPrescriptionFlag){
            navigate(`/${usermapid}/view`);
        }else{
        alert('no prescription avaliable');
        }
    };

    const[isSetPrescriptionFlag , setPrescriptionFlag] = useState<boolean>(false);

    const fetchPrescriptions = () => {
        axios.get(`${Api.$getTotalPrescription}/${usermapid}`)
            .then(response => {
                if(response.data.data.length==0){
                    setPrescriptionFlag(false);
                }else{
                    setPrescriptionFlag(true);
                }
            })
            .catch(error => {
                console.log(error);
            });
    };


    useEffect(()=>{
        fetchPrescriptions();
    },[usermapid]);

    return (
        <>
            <Header
                controller={controller}
                penVersionInfo={penVersionInfo}
                penSettingInfo={penSettingInfo}
                passwordPen={passwordPen}
                authorized={authorized}
                offlineData={offlineDataRef.current}
                drawingOffline={drawingOffline}
                usermapid={usermapid}
                patientid={patientid}
                dotsOfEachPage={dotsOfEachPage}
                canvas={canvas} pageInfo={undefined} paperSize={undefined}
                strokeProcess={strokeProcess}
                setPageInfo={setPageInfo} setOfflineData={undefined}
                pageId={pageIdd}
            />

            <div id="menu" className={classes.menu}>
                {/* <div>
                    <DrawerList usermapid={usermapid}/>
                </div> */}
                {/* <div className="menuButton">
                <Button className={`${classes.iconButton} ${classes.textField}`} onClick={handleGoToView}><FaEye className={classes.icon} />
                </Button>
                </div> */}
                <div className="menuButton">
                    <ColorPickerButton onColorChange={handleColorChange} />
                </div>
                <div className="menuButton">
                    <PenWidthSelector onWidthChange={handleWidthChange}
                    />
                </div>
                <div className="menuButton">
                  <Button className={`${classes.iconButton} ${classes.textField}`} onClick={shareCanvas}>
                    <HiShare className={classes.icon} />
                  </Button>
                </div>
                <div className="menuButton">
                    <div className="menuButton">
                        <Button
                            className={`${classes.iconButton} ${classes.textField}` }
                            onClick={() => handleToolChange('Eraser')}
                        >
                          <BiSolidEraser className={classes.icon} />
                        </Button>
                    </div>
                </div>

            </div>


            <div id="abc" className={classes.mainBackground}>
                <canvas
                    id="mainCanvas"
                    ref={mainCanvasRef}
                    className={classes.mainCanvas}
                    width={window.innerWidth- 163.25}
                    height={window.innerHeight - 163.25}
                ></canvas>
                <div className={classes.hoverCanvasContainer}>
                    <canvas
                        id="hoverCanvas"
                        className={classes.hoverCanvas}
                        width={window.innerWidth- 163.25}
                        height={window.innerHeight - 163.25}
                    ></canvas>
                </div>
            </div>
        </>
    );
};

export default PenBasic;


