import { Button, makeStyles } from '@material-ui/core';
import React, { useState } from 'react';
import { SketchPicker } from 'react-color';
import { IoColorPaletteSharp } from "react-icons/io5";
import { ShareSocial } from 'react-share-social';

const useStyle = makeStyles((theme) => ({

    icon: {
        margin: '0px',
        fontSize: '24px',
        borderRadius: '50%'
    },
    iconButton: {
        borderRadius: '60%',
        padding: '15px'
    },

}));

function ColorPickerButton({ onColorChange }) {
    const classes = useStyle();
    const [displayColorPicker, setDisplayColorPicker] = useState(false);
    const [color, setColor] = useState('#000000');


    const handleColorChange = (color) => {
        setColor(color.hex);
        onColorChange(color.hex);
    };

    return (
        <div>
            {/* backgroundColor: color */}
            <Button
                style={{  color: 'black', padding: '10px', border: 'none', cursor: 'pointer', borderRadius: '50%' }}
                onClick={() => setDisplayColorPicker(!displayColorPicker)}
            >
                <IoColorPaletteSharp className={classes.icon} />
            </Button>
            {displayColorPicker && (
                <div style={{ position: 'absolute', zIndex: 2 }}>
                    <div
                        style={{ position: 'fixed', top: '0px', right: '0px', bottom: '0px', left: '0px' }}
                        onClick={() => setDisplayColorPicker(false)}
                    />
                    <SketchPicker color={color} onChangeComplete={handleColorChange} />
                </div>
            )}
       {/* <ShareSocial  url ="url_to_share.com"
     socialTypes={['facebook','twitter','reddit','linkedin']}
   /> */}


        </div>
    );
}

export default ColorPickerButton;
