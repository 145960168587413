import React from 'react';
import { Button, makeStyles } from '@material-ui/core';
import { PenHelper } from 'web_pen_sdk';

const useStyle = makeStyles((theme) => ({
}));

const ConnectButton = ({ controller, penInfo }) => {
  const classes = useStyle();

  const scanPen = () => {
    PenHelper.scanPen();
  };

  const disconnectPen = () => {
    PenHelper.disconnect(controller);
    window.location.reload();
  }

  const InitPen = () => {
    controller.RequestInitPenDisk();
  }

  return (
    <>
      {penInfo ?
        <>
          {/* <Button onClick={InitPen} >InitPEN</Button> */}
          <Button onClick={disconnectPen} style={{ backgroundColor: '#1679AB', color: 'white', borderRadius: '4px' ,marginTop:'5px' ,marginBottom:'5px' }}  variant="contained" >disconnect</Button>
        </> :
        <Button onClick={scanPen}  style={{ backgroundColor: '#1679AB', color: 'white', borderRadius: '4px' ,marginTop:'5px' ,marginBottom:'5px' }}  variant="contained">connect</Button>
      }
    </>
  );
};

export default ConnectButton;
