import React from "react";
import { makeStyles, Typography } from "@material-ui/core";
import LogoTextImage from "../../assets/pwa152.png";
import ConnectButton from "../buttons/ConnectButton";
import PasswordButton from "../buttons/PasswordButton";
import ProfileButton from "../buttons/ProfileButton";
import FirmwareButton from "../buttons/FirmwareButton";
import OfflineButton from "../buttons/OfflineButton";
import SaveButton from "../buttons/SaveButton";
import DownloadCanvas from "../buttons/DownloadCanvas";
import { MdOutlineBatteryFull } from "react-icons/md";
// import ViewPrecriptionList from "../buttons/ViewPrecriptionList";


const useStyle = makeStyles((theme) => ({
    wrap: {
        background: "rgba(255,255,255,1)",
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        backdropFilter: "blur(4px)",
        borderBottom: "1px solid #E7E7E7",
        justifyContent: "space-between",
        "& button": {
            padding: "4px",
            color: "#121212",
            fontFamily: "Noto Sans CJK KR",
            fontStyle: "normal",
            fontWeight: "bold",
            fontSize: "13px",
            lineHeight: "16px",
            letterSpacing: "0.25px",
            minWidth: "120px",
            marginRight: "16px",
            border: "1px solid #CED3E2",
        },
    },
    logoContainer: {
        alignItems: "center",
        display: "flex",
    },
    imgStyle: {
        width: "280px",
    },
    title: {
        fontFamily: "Noto Sans CJK KR",
        fontStyle: "normal",
        fontWeight: "bold",
        fontSize: "30px",
        color: "black",
    },
    navStyle: {
        height: "50px",
        backgroundColor: "rgba(255,255,255,1)",
        display: "flex",
    },
    penIfno: {
        display: "flex",
        alignItems: "center",
        marginRight: "20px",
    },
    icon: {
        marginTop: '2px',
    }
}));

const Header = ({
    controller,
    penVersionInfo,
    penSettingInfo,
    passwordPen,
    authorized,
    offlineData,
    drawingOffline,
    usermapid,
    patientid,
    dotsOfEachPage,
    canvas,
    pageInfo,
    paperSize,
    strokeProcess,
    setPageInfo,
    setOfflineData,
    pageId

}) => {
    const classes = useStyle();
    const maxBatteryValue = 300;

    return (
        <div className={classes.wrap}>
            <div className={classes.logoContainer}>
                <img src={LogoTextImage} className={classes.imgStyle} alt="logo" />
            </div>
            <div className={classes.navStyle}>
                {authorized ? (
                    <OfflineButton
                        controller={controller}
                        offlineData={offlineData}
                        // setOfflineData={setOfflineData}
                        // strokeProcess={strokeProcess}
                        // setPageInfo={setPageInfo}
                        // pageInfo={pageInfo}
                        // paperSize={paperSize}
                        drawingOffline={drawingOffline}
                        usermapid={usermapid}
                        patientid={patientid}
                        dotsOfEachPage={dotsOfEachPage}
                    />
                ) : (
                    ""
                )}

                {authorized ? (
                    <DownloadCanvas
                        usermapid={usermapid}
                        patientid={patientid}
                        dotsOfEachPage={dotsOfEachPage}
                        controller={controller}
                        offlineData={offlineData}
                        drawingOffline={drawingOffline}
                        canvas={canvas}
                        pageId={pageId}
                    />
                ) : (
                    ""
                )}


                <ConnectButton controller={controller} penInfo={penVersionInfo} />

                <Typography variant="subtitle2" className={classes.penIfno}>
                    {authorized && (
                        <div>
                            <MdOutlineBatteryFull className={classes.icon} />
                            {Math.round((penSettingInfo.Battery / maxBatteryValue) * 100)}%
                        </div>
                    )}
                </Typography>


            </div>
        </div>
    );
};

export default Header;
