import React from "react";
import { makeStyles, Typography } from "@material-ui/core";
import LogoTextImage from "../../assets/pwa152.png";
import ConnectButton from "../buttons/ConnectButton";
import PasswordButton from "../buttons/PasswordButton";
import ProfileButton from "../buttons/ProfileButton";
import FirmwareButton from "../buttons/FirmwareButton";
import OfflineButton from "../buttons/OfflineButton";
import SaveButton from "../buttons/SaveButton";
import DownloadCanvas from "../buttons/DownloadCanvas";
import { MdOutlineBatteryFull } from "react-icons/md";
// import ViewPrecriptionList from "../buttons/ViewPrecriptionList";


const useStyle = makeStyles((theme) => ({
    wrap: {
        background: "rgba(255,255,255,1)",
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        backdropFilter: "blur(4px)",
        borderBottom: "1px solid #E7E7E7",
        justifyContent: "space-between",
        "& button": {
            padding: "4px",
            color: "#121212",
            fontFamily: "Noto Sans CJK KR",
            fontStyle: "normal",
            fontWeight: "bold",
            fontSize: "13px",
            lineHeight: "16px",
            letterSpacing: "0.25px",
            minWidth: "120px",
            marginRight: "16px",
            border: "1px solid #CED3E2",
        },
    },
    logoContainer: {
        alignItems: "center",
        display: "flex",
    },
    imgStyle: {
        width: "280px",
    },
    title: {
        fontFamily: "Noto Sans CJK KR",
        fontStyle: "normal",
        fontWeight: "bold",
        fontSize: "30px",
        color: "black",
    },
    navStyle: {
        height: "50px",
        backgroundColor: "rgba(255,255,255,1)",
        display: "flex",
    },
    penIfno: {
        display: "flex",
        alignItems: "center",
        marginRight: "20px",
    },
    icon: {
        marginTop: '2px',
    }
}));

const Headerv1 = () => {
    const classes = useStyle();
    console.log('v111111');
    return (
        <div className={classes.wrap}>
            <div className={classes.logoContainer}>
                <img src={LogoTextImage} className={classes.imgStyle} alt="logo" />
            </div>
        </div>
    );
};

export default Headerv1;
