/* eslint-disable @typescript-eslint/no-unused-vars */
import { makeStyles } from '@material-ui/core';
import React from 'react';
import { useParams } from 'react-router-dom';
import PenBasic from '../renderer/PenBasic';
import ViewPrescriptionList from '../buttons/ViewPrecriptionList';


const useStyle = makeStyles((theme) => ({
  mainBackground: {
    width: '100%',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    textAlign: 'center',
    fontWeight: 'bold'
  },
  title: {
    margin: '15px',
  }
}));


const PenSDKSample = () => {
  const classes = useStyle();
//   const {id}  = useParams();
//   const { id } = useParams<{ id: string }>();
  const { usermapid, patientid } = useParams();


  return (
    <div className={classes.mainBackground}>
      <PenBasic usermapid={usermapid} patientid={patientid} />
    </div>
  );
};

export default PenSDKSample;

